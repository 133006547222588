import React, { useEffect, useState } from 'react'
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreatePetInformation } from '../../../lib/Api/RentalAppApi';
import { useDispatch, useSelector } from 'react-redux';
import 'react-phone-input-2/lib/style.css'
import { BsTrash } from 'react-icons/bs';
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import { compareObjects } from '../../../hooks/ValidateRentalApp';
import { addRentals } from '../../../Redux/UserAuthSlice/RentalSlice';

const Information = {
  kind: "",
  description: ""

}

const PetInformation = ({ handleNext, handleBack }) => {
  const [havePets, setHavePets] = useState(true)
  const [loading, setLoading] = useState(false)
  const [serviceAnimale, setServiceAnimal] = useState(false)
  // const [quantity, setQuantity] = useState(0)

  const { rentalData } = useSelector((state) => state?.rental)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  let rentalApp = rentalData?.pet_info

  const schema = yup.object().shape({
    quantity: yup.string().required("Quantity is required"),
    pets: yup.array().of(
      yup.object().shape({
        kind: yup.string().required("Kind is required"),
        description: yup.string().required("Description is required"),
      })
    ),
  })

  const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema), defaultValues: { pets: [Information] } });
  const { fields, append, remove, } = useFieldArray({
    control,
    name: 'pets'
  });

  const OnSubmit = async (data) => {
    let payload = {
      "have_any_pets": havePets === true ? 1 : 0,
      "quantity": Number(data?.quantity),
      "service_animal": serviceAnimale === true ? 1 : 0,
      "pets": data?.pets
    }
    let check = !compareObjects(rentalApp, payload)
    if (check) {
      try {
        let payload = {
          "app_id": rentalData?.id,
          "have_any_pets": havePets,
          "quantity": data?.quantity,
          "service_animal": serviceAnimale,
          "pets": data.pets
        }
        setLoading(true)
        let { res } = await CreatePetInformation(payload)
        if (res) {
          dispatch(addRentals(res[0]?.rental_application))
          setLoading(false)
          handleNext(3)

        }
        else {
          setLoading(false)
        }
      }
      catch (err) {
        setLoading(false)

      }
    }
    else {
      handleNext(3)
    }
  }

  const handleSend = async (e) => {
    e.preventDefault()
    try {
      let payload = {
        "app_id": rentalData?.id,
        "have_any_pets": havePets,
      }
      setLoading(true)
      let { res } = await CreatePetInformation(payload)
      if (res) {
        dispatch(addRentals(res[0]?.rental_application))
        setLoading(false)
        handleNext(3)

      }
      else {
        setLoading(false)
      }
    } catch (err) {
    }
  }


  useEffect(() => {
    if (rentalApp) {
      setHavePets(rentalApp?.have_any_pets === 1 ? true : false)
      setServiceAnimal(rentalApp?.service_animal === 1 ? true : false)
      // let payload = {
      //   quantity: rentalApp?.quantity,
      //   pets: rentalApp?.pets.map(app => ({
      //     kind: app?.kind,
      //     description: app?.description
      //   })),
      // }
      let payload = {
        quantity: rentalApp?.quantity,
        pets: rentalApp?.pets?.length > 0 ? rentalApp.pets.map(app => ({
          kind: app?.kind,
          description: app?.description,
        })) : [Information], // Add a default `pets` item if `rentalApp.pets` is empty
      };
      reset(payload)
    }
  }, [reset, rentalApp])

  // console.log("havePets", havePets)

  return (
    <>
      <form onSubmit={havePets === false ? (e) => handleSend(e) : handleSubmit(OnSubmit)} >
        <h2 className='text-[20px] font-bold text-center p-8'>{t('pet_info')}</h2>
        <div className='xl:h-[400px]  lg:h-[400px]  md:h-[400px] h-full xl:overflow-y-auto lg:overflow-y-auto md:overflow-y-auto '>
          <div className='grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 md:gap-10 xl:gap-10 lg:gap-10'>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('do_you_have_pet')} </label>
              <div className="flex justify-start">
                <label className='relative inline-flex items-center cursor-pointer'>
                  <input
                    type="checkbox"
                    // name={list?.name}
                    checked={havePets ? true : false}
                    className="sr-only peer"
                    defaultValue={havePets}
                    onChange={(e) => setHavePets(e.target.checked)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>

                  <label className="form-check-label inline-block text-gray-800 mx-3">
                    {havePets === true ? t('yes') : t('no')}
                  </label>

                </label>
              </div>
            </div>
            <div className={`flex flex-col mb-4 ${havePets === false && 'hidden'}`}>
              <label className='mb-2 text-[16px] font-semibold'>{t('how_many_pet')}<span className='text-red-500'>*</span></label>
              <input type="number" placeholder={t('how_many_pet')}
                {...register('quantity')}
                className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
              {errors.quantity && (
                <span className='text-red-500 text-sm '>{errors.quantity.message}</span>
              )}
            </div>
            <div className={`flex flex-col mb-4 ${havePets === false && 'hidden'}`}>
              <label className='mb-2 text-[16px] font-semibold'>{t('service_animal')} </label>
              <div className="flex justify-start">
                <label className='relative inline-flex items-center cursor-pointer'>
                  <input
                    type="checkbox"
                    checked={serviceAnimale ? true : false}
                    className="sr-only peer"
                    defaultValue={serviceAnimale}
                    onChange={(e) => setServiceAnimal(e.target.checked)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>

                  <label className="form-check-label inline-block text-gray-800 mx-3">
                    {serviceAnimale === true ? t('yes') : t('no')}
                  </label>

                </label>
              </div>
            </div>
          </div>
          {
            fields.map((field, index) => {
              return (
                <div className={`border rounded-md px-4 py-2 my-2 ${havePets === false && 'hidden' }`} key={index}>
                  <div className='flex justify-end'>
                    {
                      index !== 0 &&
                      <button type='button' onClick={() => remove(index)} className=' p-2 bg-red-500 rounded-md '><BsTrash className='text-white' /></button>
                    }
                  </div>

                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'>{t('what_kind_pet')}<span className='text-red-500'>*</span></label>
                    <input type="text" placeholder={t('what_kind_pet')}
                      name={`pets[${index}].kind`}
                      {...register(`pets.${index}.kind`)}
                      defaultValue={field.kind}
                      className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                    {errors.pets && errors.pets[index] && errors.pets[index].kind && (
                      <span className='text-red-500 text-sm '>{errors.pets[index].kind.message}</span>
                    )}
                  </div>
                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'>{t('describe_pet')}<span className='text-red-500'>*</span></label>
                    <textarea placeholder={t('describe_pet')}
                      name={`pets[${index}].description`}
                      {...register(`pets.${index}.description`)}
                      rows={5}
                      defaultValue={field.description}
                      className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' ></textarea>
                    {errors.pets && errors.pets[index] && errors.pets[index].description && (
                      <span className='text-red-500 text-sm '>{errors.pets[index].description.message}</span>
                    )}
                  </div>
                  {/* </div> */}
                </div>
              )
            })
          }
        </div>





        <div className={`${havePets ? 'flex' : 'hidden'}  justify-end my-4`}>
          <button onClick={() => append(Information)} type='button' className=' px-3 py-2 bg-green-500 rounded-md text-white font-semibold '>{t('add_m_lbl')}</button>
        </div>



        <div className={`flex justify-between items-center`}>
          <div className='flex justify-end py-3'>
            <button onClick={() => handleBack(3)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
          </div>

          <div className='flex justify-end py-3'>
            <button className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>

              {
                loading ? <Loader type="saveLoder" />
                  :
                  t('Next')
              }
            </button>
          </div>
        </div>
      </form>

    </>
  )
}

export default PetInformation