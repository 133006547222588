
import { loadStripe } from '@stripe/stripe-js';
export const stripePromise = loadStripe('pk_test_51M9MTsEqK3iozBJUrY041GSpCvW4NR5RyLSWLUHzBaLddv71ko2gXgtRDaks8Rdpcdmckn2tr3HQO1Bwk289iAth008Pc2XCLh',{
    locale: 'es', // Set the locale to 'es' for Spanish
  });


  export const stripePromiseDta = (key) => {
   return loadStripe(key,{
    locale: 'es', // Set the locale to 'es' for Spanish
  });
  } 



// Set language to Spanish
// stripePromise.then((stripe) => {
//     stripe.elements().setLocale('es');
//   });


  